import React from 'react';
import Link from '../link';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import More from './more';
import * as styles from './ankerUserIntro.module.scss';



const ankerUserInfo = ({ temp, more = true, location }) => {
  return (
    <IntlContextConsumer>{
      ({ languages, language: currentLocale }) => <>
        <div className={styles.ankerUserIntro}>
          {
            temp.title
              ? <p className={styles.title}>{temp.title}</p>
              : null
          }
          <div className="userIntroContainer">
            {
              temp.content.map((item, i) => (
                <div key={i} className="containerWrap">
                  {currentLocale === 'en' ? <div>
                    <div className="photo hoverPhoto">
                      <img src={item.image}></img>
                    </div>
                    <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: item.text }} ></div>
                    </div>
                  </div> :
                    <Link to={item.id ? `${temp.more.url}#${item.id}` : temp.more.url} css={{ textDecoration: 'none' }}>
                      <div className="photo hoverPhoto">
                        <img src={item.image}></img>
                      </div>
                      <div className="content">
                        <div dangerouslySetInnerHTML={{ __html: item.text }} ></div>
                      </div>
                    </Link>
                  }
                  {
                    more && currentLocale !== 'en'
                      ? <More url={temp.more.url} id={item.id ? item.id : ''} fontSize='36px' location={location} />
                      : null
                  }
                </div>
              ))
            }
          </div>
          {/* <div className={styles.list}>
        {
          edges[0].node.image.map((item, i) => (
            <li key={item}>
              <Link target="_blank" to={item}>
                <img src={item.url}></img>
                <h3 className={styles.job} dangerouslySetInnerHTML={{ __html: edges[0].node.text[i] }} />
              </Link>
            </li>
          ))
        }
      </div> */}
        </div>
      </>

    }</IntlContextConsumer>

  )


};

export default ankerUserInfo;
