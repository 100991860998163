import React from 'react';

const Story = ({ temp = {} }) => {
  return (
    <div>
      {
        temp.map((item, i) => (
          <div key={`${item}${i}`}>
            <div dangerouslySetInnerHTML={{ __html: item }}></div>
          </div>
        ))
      }
    </div>
  );
};

export default Story;
